.chat {
    margin: var(--defaultSpacing);
    margin-top: var(--headerHeight)
}

.chat-box {
    border: 2px solid white;
    border-radius: 10px;
    display: inline;
    height: fit-content;
    margin: .5rem 0;
    width: fit-content;
    animation: slidein 1s linear;
    animation-fill-mode: backwards;
    position: relative;
}

.chat-box.multimedia {
    width: 75%;
}

.multimedia-container {
    display: flex;
}

.chat-box img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 100%;
}

.chat-box-text {
    padding: 1rem;
}

.studio-messages {
    display: flex;
    flex-direction: column;
    max-width: 60%;
}

.reply-options {
    width: 100%;
    text-align: right;
}

.reply-option {
    animation: display-replies 0.25s linear;
    animation-fill-mode: backwards;
}

@keyframes slidein {
    0%   { opacity: 0; left: -20px; display: block }
    100% { opacity: 1; left: 0px; }
  }

@keyframes display-replies {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@media only screen and (max-width: 768px) {
    .studio-messages {
        max-width: 100%;
    }
}