@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

:root {
  --bgColor: black;
  --textColor: white;
  --fontFamily: 'Roboto Mono', monospace, 'Roboto', sans-serif;
  --accentColor: rgb(189 195 199);
  --headerHeight: 7.5vh;
  --defaultSpacing: 2rem;
  --defaultFontSize: 16px;
}

body {
  margin: 0;
  font-size: var(--defaultFontSize);
  font-family: var(--fontFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background: var(--textColor);
  outline: none;
  border: none;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 3px 3px 2px var(--accentColor);
  font-family: var(--fontFamily);
  font-size: var(--defaultFontSize);
  cursor: pointer;
}
button:hover {
  background: #efefef;
}
button:active {
  box-shadow: 1px 1px 1.5px var(--accentColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--textColor)
}

@media only screen and (max-width: 768px) {
  :root {
    --defaultFontSize: 14px;
    --defaultSpacing: 1rem;
  }

  button {
    text-align: right;
  }
}