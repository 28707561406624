.App {
  background-color: var(--bgColor);
  color: var(--textColor);
  min-height: 100vh;
  overflow: scroll;
}

.App-header {
  background: var(--textColor);
  color: var(--bgColor);
  height: var(--headerHeight);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.App-header-logo-text {
  display: flex;
  flex-direction: column;
}

.App-header-logo-text p {
  margin: 0;
  font-size: 15px;
}

.App-header-logo-text small {
  font-size: 11px;
}

.App-header-contact a {
  margin: 0 .5rem;
  color: var(--bgColor);
  font-size: 24px;
}
.App-header-contact a:hover {
  color: var(--accentColor);
  filter: brightness(35%);
}

.App-header-logo {
  display: flex;
  height: var(--headerHeight);
  align-items: center;
}
.App-header-logo img {
  width: calc(var(--headerHeight)/1.35);
  margin-right: .5rem;
}

.App-header > * {
  margin: 0 var(--defaultSpacing);
}
